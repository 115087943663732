import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Divider,
  Container,
} from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AwardsDialog from "./AwardsDialog";
import axios from "axios";
import ImportantInstructionsAwards from "./ImportantInstructionsAwards";

const Awards = () => {
  const [awards, setAwards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  useEffect(() => {
    const fetchAwards = async () => {
      const storedUsername = localStorage.getItem("username");
      const storedToken = localStorage.getItem("token");

      console.log("Username almacenado en localStorage:", storedUsername);
      console.log("Token almacenado en localStorage:", storedToken);

      try {
        const response = await axios.get(
          "https://api.master-bikas.com/api/prizes",
          {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );

        console.log("Premios recibidos:", response.data);
        setAwards(response.data.prizes || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching prizes:", error);
        console.log("Detalles del error:", error.response?.data);
        setLoading(false);
      }
    };
    fetchAwards();
  }, []);

  const handleClaim = async (award) => {
    const storedToken = localStorage.getItem("token");
  
    if (!storedToken) {
      setDialogMessage("Por favor, inicie sesión para reclamar premios.");
      setDialogOpen(true);
      return;
    }
  
    try {
      const response = await axios.post(
        "https://api.master-bikas.com/api/prizes/claim",
        { prizeId: award.id },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
  
      setDialogMessage(response.data.message);
  
      if (response.data.success) {
        setAwards((prevAwards) =>
          prevAwards.map((prevAward) =>
            prevAward.id === award.id
              ? {
                  ...prevAward,
                  stock: response.data.updatedStock, 
                }
              : prevAward
          )
        );
      }
    } catch (error) {
      console.error("Error al reclamar el premio:", error);
      setDialogMessage(
        error.response?.data?.message || "Hubo un error al reclamar el premio."
      );
    }
    setDialogOpen(true);
  };
  

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box sx={{ bgcolor: "#FEFEFE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ p: 5, mt: 2, mb: 5 }}>
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Typography
            variant="h4"
            sx={{
              color: "#0cc0df",
              textAlign: "center",
              mb: 2,
              fontWeight: "bold",
            }}
          >
            PREMIOS
          </Typography>

          <ImportantInstructionsAwards />

          <Box sx={{ my: 4, p: 3, bgcolor: "#f9f9f9", borderRadius: "8px" }}>
            <Typography variant="h6" gutterBottom>
              Consideraciones para los videojuegos:
            </Typography>
            <Typography variant="body2" gutterBottom>
              - Principal/Primaria: Podrá jugar desde su usuario, con o sin
              conexión a internet.
            </Typography>
            <Typography variant="body2" gutterBottom>
              - Secundaria: Podrá jugar obligatoriamente en el usuario que
              nosotros le enviemos, con conexión a internet fija.
            </Typography>
          </Box>
        </Container>
        <Divider sx={{ my: 4 }} />

        {loading ? (
          <Typography>Cargando premios...</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Img</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Nombre del premio</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Descripción</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Categoría</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Puntos requeridos</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Stock</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Acción</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {awards.map((award) => (
                  <TableRow key={award.id}>
                    <TableCell>
                      <img
                        src={award.imageUrl}
                        alt={award.name}
                        style={{
                          width: "100px",
                          height: "auto",
                          borderRadius: "8px",
                        }}
                      />
                    </TableCell>
                    <TableCell>{award.name}</TableCell>
                    <TableCell>{award.description}</TableCell>
                    <TableCell>{award.category}</TableCell>
                    <TableCell>{award.pointsRequired}</TableCell>
                    <TableCell>
                      {award.stock > 0 ? award.stock : "Agotado"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color={award.stock > 0 ? "primary" : "secondary"}
                        disabled={award.stock === 0}
                        onClick={() => handleClaim(award)}
                      >
                        {award.stock > 0 ? "Reclamar premio" : "Sin stock"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Footer />
      <AwardsDialog
        open={dialogOpen}
        handleClose={handleCloseDialog}
        success={dialogMessage}
        disableEnforceFocus={false}
      />
    </Box>
  );
};

export default Awards;
